export const seo = {
  url: '404',
  title: {
    pl: '404 | Strona nie odnaleziona',
    en: '404 | Page not found',
  },
  desc: {
    pl: 'Wygląda na to, że strona, którą chcesz odwiedzić nie istnieje. Kilknij przycisk, aby wrócić na stronę główną',
    en: 'Seems like the page you are looking for does not exist. Go to homepage by clicking the button',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Ślepa uliczka',
    en: 'End of the road',
  },
  desc: {
    pl: 'Wygląda na to, że strona, którą chcesz odwiedzić nie istnieje. Kilknij przycisk, aby wrócić na stronę główną',
    en: 'Seems like the page you are looking for does not exist. Go to homepage by clicking the button',
  },
  button: {
    text: {
      pl: 'Zawróć!',
      en: 'Turn back!',
    },
    link: '/',
  },
}
